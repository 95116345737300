import { useEffect } from 'react';

/**
 *
 * 200 x 200
 */
const SmallSquare = () => {
  useEffect(() => {
    if (window) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }, []);

  return (
    <div className="text-center">
      <ins
        className="adsbygoogle"
        style={{ display: 'inline-block', width: '200px', height: '200px' }}
        data-ad-client="ca-pub-1919598055512436"
        data-ad-slot="4246063582"
      ></ins>
    </div>
  );
};

export default SmallSquare;
