import NavBar from '../NavBar';

const Fallback = () => {
  return (
    <div>
      <NavBar />
      <div>loading...</div>
    </div>
  );
};

export default Fallback;
