import { useQuery } from '@apollo/client';
import { PlusIcon } from '@heroicons/react/solid';
import { GetStaticPaths, GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';
import { ClimbingBoxLoader } from 'react-spinners';
import { SmallSquare } from '../../components/Adsense/Common';
import { Banner as MobileBanner } from '../../components/Adsense/Mobile';
import Fallback from '../../components/Fallback';
import Header from '../../components/Header';
import { MenuCardSquare, MenuCardWide } from '../../components/MenuCard';
import NavBar from '../../components/NavBar';
import {
  GetStoreByNameQuery,
  GetStoreByNameQueryVariables,
  GetStoreMenusQuery,
  GetStoreMenusQueryVariables,
  GetStoresQuery,
} from '../../generated';
import { GET_STORES, GET_STORE_BY_NAME, GET_STORE_MENUS } from '../../graphql/queries';
import { addApolloState, initializeApollo } from '../../utils/apollo';

interface StoreProps extends InferGetStaticPropsType<typeof getStaticProps> {}

const Store: React.FC<StoreProps> = ({ menus, store }) => {
  const router = useRouter();
  const pathname = router.pathname;
  const storeEngName = router.query.storeEngName as string;
  const { data: getStoreMenusData } = useQuery<GetStoreMenusQuery, GetStoreMenusQueryVariables>(
    GET_STORE_MENUS,
    {
      variables: { storeEngName },
    }
  );
  const { data: getStoreByNameData } = useQuery<GetStoreByNameQuery, GetStoreByNameQueryVariables>(
    GET_STORE_BY_NAME,
    {
      variables: { storeEngName },
    }
  );
  const isMobile = useMediaQuery({
    query: `(max-width: 480px)`,
  });
  if (router.isFallback) {
    return <Fallback />;
  }
  if (getStoreMenusData && getStoreByNameData) {
    const { getStoreMenus } = getStoreMenusData;
    const { getStoreByName } = getStoreByNameData;
    const keywords = getStoreMenus
      .slice(0, 10)
      .map((menu) => menu?.menuName)
      .toString();

    const onCreateMenuButtonClick = () => {
      router.push({
        pathname: '/upload/new',
        query: { store: getStoreByName?.storeEngName as string },
      });
    };

    return (
      <div>
        <Header
          title={`${getStoreByName?.storeName} | 커스텀 메뉴`}
          description={`${getStoreByName?.storeName} 커스텀 메뉴를 확인해보세요.`}
          keywords={`커스텀메뉴,커스텀,메뉴,만들기,조합,레시피,음료,꿀조합,${keywords}`}
          url={`https://custommenu.swygbro.com${pathname}`}
        />
        <NavBar />

        <main className="py-8 space-x-12">
          <div className="px-6 space-y-4">
            <div className="flex items-center justify-between">
              <div
                className={`relative w-32 h-10 ${
                  !(getStoreByName?.logoImageOriginalLocation as string) && 'opacity-30'
                }`}
              >
                <Image
                  src={
                    (getStoreByName?.logoImageOriginalLocation as string) ||
                    '/assets/images/image-not-found.png'
                  }
                  alt="store logo"
                  layout="fill"
                  objectFit="contain"
                  priority={true}
                />
              </div>

              <button
                onClick={onCreateMenuButtonClick}
                className="flex items-center space-x-2 pl-4 pr-3 py-1 border border-cmGreen-400 text-cmGreen-400 hover:opacity-60 active:opacity-100"
              >
                <span>나만의 메뉴 만들기</span>
                <PlusIcon width={16} height={16} />
              </button>
            </div>
            {isMobile ? (
              <div className="grid grid-cols-1 gap-y-8 justify-items-center px-0">
                {getStoreMenus.map((menu, i) => (
                  <>
                    {(i + 1) % 5 === 0 && <MobileBanner />}
                    <MenuCardWide
                      key={`menu-${i}`}
                      menuId={menu?.id as number}
                      storeEngName={storeEngName}
                      eatenCount={menu?.eatenCount.toString() as string}
                      imageUrl={menu?.imageLocation as string}
                      menuName={menu?.menuName as string}
                      price={menu?.price.toString() as string}
                      viewCount={menu?.viewCount.toString() as string}
                    />
                  </>
                ))}
              </div>
            ) : (
              <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 2xl:grid-cols-7 gap-x-2 gap-y-8 justify-items-center px-0">
                {getStoreMenus.map((menu, i) => (
                  <>
                    {(i + 1) % 10 === 0 && <SmallSquare />}
                    <MenuCardSquare
                      key={`menu-${i}`}
                      menuId={menu?.id as number}
                      storeEngName={storeEngName}
                      eatenCount={menu?.eatenCount.toString() as string}
                      imageUrl={menu?.imageLocation as string}
                      menuName={menu?.menuName as string}
                      price={menu?.price.toString() as string}
                      viewCount={menu?.viewCount.toString() as string}
                    />
                  </>
                ))}
              </div>
            )}
            {getStoreMenus.length === 0 && (
              <div className="pt-10 text-center text-2xl font-bold text-gray-300">
                아직 메뉴가 없습니다.
              </div>
            )}
          </div>
        </main>
      </div>
    );
  }
  return (
    <div>
      <Header
        title={`커스텀 메뉴`}
        description={`커스텀 메뉴를 확인해보세요.`}
        keywords={`커스텀메뉴,커스텀,메뉴,만들기,조합,레시피,음료,꿀조합,`}
        url={`https://custommenu.swygbro.com${pathname}`}
      />
      <NavBar />
      <div className="text-center pt-20 pb-14 text-2xl font-bold text-gray-400">
        <ClimbingBoxLoader loading={true} color="rgba(16, 108, 41, 1)" />
      </div>
      <div className="flex w-full justify-center">
        <button
          onClick={() => router.back()}
          className="bg-cmGreen-400 text-white px-3 py-2 hover:opacity-80 active:opacity-100"
        >
          뒤로 돌아가기
        </button>
      </div>
    </div>
  );
};

export const getStaticPaths: GetStaticPaths = async () => {
  const apolloClient = initializeApollo();
  const { data } = await apolloClient.query<GetStoresQuery>({
    query: GET_STORES,
  });

  return {
    fallback: true,
    paths: data.getStores.map((store) => ({
      params: {
        storeEngName: store?.storeEngName,
      },
    })),
  };
};

export const getStaticProps = async (context: GetStaticPropsContext) => {
  const apolloClient = initializeApollo();
  const storeEngName = context?.params?.storeEngName as string;
  const { data } = await apolloClient.query<GetStoreMenusQuery, GetStoreMenusQueryVariables>({
    query: GET_STORE_MENUS,
    variables: { storeEngName },
  });
  const { data: storeData } = await apolloClient.query<
    GetStoreByNameQuery,
    GetStoreByNameQueryVariables
  >({
    query: GET_STORE_BY_NAME,
    variables: { storeEngName },
  });

  return addApolloState(apolloClient, {
    props: {
      menus: data,
      store: storeData,
    },
    revalidate: 1,
  });
};

export default Store;
