import { useEffect } from 'react';

/**
 *
 * 320 x 100
 */
const Banner = () => {
  useEffect(() => {
    if (window) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }, []);

  return (
    <div className="text-center">
      <ins
        className="adsbygoogle"
        style={{ display: 'inline-block', width: '320px', height: '100px' }}
        data-ad-client="ca-pub-1919598055512436"
        data-ad-slot="6783124833"
      ></ins>
    </div>
  );
};

export default Banner;
